<template>
  <div>
    <Preloader :loading="isWorkshopLoading" />
    <div v-if="workshop && !isWorkshopLoading" class="workshop">
      <HalfImageHero
        :title="workshop.title"
        :box="
          createBoxData(
            workshop.external_reg_url,
            workshop.quantity,
            workshop.registered
          )
        "
        :image="
          workshop?.primary_image
            ? workshop.primary_image
            : require('@/assets/img/village-sample.jpg')
        "
      >
        <template #button>
          <router-link to="#workshop-description" class="workshop__explore">
            <Button fullWidth white class="mb-3"> explore</Button>
          </router-link>
          <!--          <a
            v-if="workshop.external_reg_url"
            :href="workshop.external_reg_url"
            style="text-decoration: none"
          >
            <Button fullWidth> register</Button>
          </a>
          <router-link
            v-else
            :to="`/workshop/${workshop.id}/apply`"
            @click="saveTitle(workshop.title)"
            style="text-decoration: none"
          >
            <Button fullWidth> register</Button>
          </router-link>-->
        </template>
      </HalfImageHero>

      <SectionWithBg id="workshop-description">
        <template #heading> Workshop Overview</template>
        <template #body>
          <div v-html="workshop.description" class="mr-5" />
        </template>
      </SectionWithBg>

      <div
        v-show="workshop.instructor && workshop.instructor.length"
        class="bio padding-x"
      >
        <Heading noDecoration text-align="center" class="bio__header">
          Instructor{{ workshop.instructor.length > 1 ? "s" : null }}:
        </Heading>
        <div
          v-for="(instructor, index) in workshop.instructor"
          :key="instructor.instructor + index"
        >
          <div class="bio__wrapper">
            <div
              v-show="instructor.bio_image"
              class="bio__img"
              :style="`background-image: url(${instructor.bio_image})`"
            />
            <div class="bio__heading">
              {{ instructor.instructor }}
              <br />
              <small>
                <i>{{ instructor.job_title }}</i>
                <br />
                <small>{{ instructor.organization }}</small>
              </small>
              <SocialMedia :social="instructor.social_media" />
            </div>

            <div v-html="instructor.bio" class="bio__description" />
          </div>
        </div>
      </div>

      <div v-if="Object.keys(workshop.agenda).length" class="padding-x agenda">
        <Heading noDecoration text-align="center" class="agenda__header">
          Agenda:
        </Heading>
        <dl
          v-for="[topic, list] in Object.entries(workshop.agenda)"
          :key="topic"
          class="agenda__item"
        >
          <dt class="agenda__topic">{{ topic }}</dt>
          <dd
            v-for="(item, index) in list"
            :key="item + index"
            class="agenda__item"
          >
            {{ item }}
          </dd>
        </dl>
      </div>
      <ContactUs title="Interested but still have questions?" />
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import Preloader from "@/components/common/Preloader";
import Heading from "@/components/common/Heading";
import Button from "@/components/common/Button";
import HalfImageHero from "@/components/common/HalfImageHero";
import SectionWithBg from "@/components/common/SectionWithBg";
import ContactUs from "@/components/home/ContactUs";
import SocialMedia from "@/components/common/SocialMedia";

export default {
  name: "Workshop",
  props: {},
  components: {
    Heading,
    Preloader,
    Button,
    HalfImageHero,
    SectionWithBg,
    SocialMedia,
    ContactUs,
  },
  data: () => ({
    isWorkshopLoading: false,
    workshop: null,
  }),
  computed: {
    startTimeHours() {
      return this.getTime(this.workshop.start, "time");
    },
    endTimeHours() {
      return this.getTime(this.workshop.end, "time");
    },
    confDay() {
      return this.getTime(this.workshop.start, "day");
    },
    confTime() {
      return this.workshop?.start && this.workshop?.end
        ? `${this.startTimeHours} - ${this.endTimeHours}`
        : "";
    },
  },
  mounted() {
    this.isWorkshopLoading = true;
    this.getWorkshop();
  },
  methods: {
    ...mapActions(["GET"]),
    getWorkshop() {
      return this.GET({
        route: `public/workshop/${this.$route.params.workshop_id}`,
      })
        .then((resp) => {
          this.workshop = resp.data;
          this.isWorkshopLoading = false;
        })
        .catch((err) => {
          console.log(err);
          this.isWorkshopLoading = false;
        });
    },
    saveTitle(data) {
      this.$store.commit("setWorkshopTitle", data);
    },
    createBoxData(external, quantity, registered) {
      let subtitle;
      if (!external && quantity - registered <= 5)
        subtitle = `There are ${quantity - registered} free places left`;
      return {
        name: "When",
        title: this.confTime + " " + this.confDay,
        subtitle,
      };
    },
    getTime(dateString, modifyer) {
      const startDate = new Date(dateString);
      const options =
        modifyer === "time"
          ? {
              timeZone: "US/Alaska",
              hour12: true,
              hour: "2-digit",
              minute: "2-digit",
            }
          : {
              month: "short",
              day: "2-digit",
              year: "numeric",
            };
      return startDate.toLocaleString("en-US", options);
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/scss/_variables";

.workshop {
  &__explore {
    text-decoration: none;
    display: none;
    @media (min-width: $media-sm) {
      display: block;
    }
  }
}

.bio {
  margin-top: 35px;

  &__wrapper {
    position: relative;
    display: grid;
    grid-template-columns: 1fr 2fr;
    align-items: end;
    padding-top: 50px;

    :after {
      content: "";
      background: $light-gray;
      height: 93%;
      width: 47%;
      position: absolute;
      top: 5%;
      left: -15px;
      z-index: -1;
    }
  }

  &__img {
    width: 136px;
    height: 184px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    margin-right: 15px;
  }

  &__heading {
    grid-column-start: 2;
    grid-column-end: 3;
    font-family: $sarabun;
    font-weight: $sarabunBold;
    font-size: 24px;
    line-height: 32px;
    color: $dark-gray;
  }

  &__description {
    grid-column-start: 1;
    grid-column-end: 3;
    padding-bottom: 15px;
    line-height: 32px;
    letter-spacing: 0.05em;
  }

  @media (min-width: $media-xs) {
    &__wrapper {
      :after {
        width: 21%;
      }
    }
    &__img {
      width: 216px;
      height: 296px;
    }
  }

  @media (min-width: $media-sm) {
    margin-top: 120px;

    &__header {
      margin-bottom: 56px;
    }

    &__wrapper {
      align-items: start;

      :after {
        height: 100%;
        width: 23%;
        top: 0;
        left: -23px;
      }
    }

    &__img {
      grid-row-start: 1;
      grid-row-end: 3;
      width: 296px;
      height: 528px;
      margin-right: 25px;
    }

    &__heading {
      grid-row-start: 1;
      grid-row-end: 2;
      align-self: end;
      font-size: 32px;
      line-height: 40px;
      letter-spacing: 0.08em;
    }
    &__description {
      grid-column-start: 2;
      grid-row-start: 2;
      grid-row-end: 3;
    }
  }

  @media (min-width: $media-lg) {
    &__header {
      margin-bottom: 85px;
    }

    &__wrapper {
      :after {
        width: 49%;
        left: -33%;
      }
    }

    &__img {
      width: 465px;
      height: 616px;
      margin-right: 120px;
    }

    &__heading {
      font-size: 40px;
    }

    &__description {
      padding-top: 15px;
    }
  }
}

.agenda {
  margin-top: 35px;

  &__topic {
    font-family: $sarabun;
    font-weight: bold;
    font-size: 24px;
    line-height: 32px;
    letter-spacing: 0.08em;
    color: $dark-gray;
    margin-top: 20px;

    &::before {
      content: "";
      display: inline-block;
      vertical-align: middle;
      width: 40px;
      height: 2px;
      background: $dark-gray;
      margin-right: 13%;
    }
  }

  &__item dd {
    display: list-item;
    margin-left: 40%;
  }

  @media (min-width: $media-xs) {
    &__topic {
      &::before {
        margin-right: 22%;
      }
    }
    &__item dd {
      margin-left: 35%;
    }
  }

  @media (min-width: $media-sm) {
    margin-top: 120px;
    &__header {
      margin-bottom: 40px;
    }
  }

  @media (min-width: $media-lg) {
    &__header {
      margin-bottom: 74px;
    }
    &__topic {
      font-size: 40px;
      line-height: 56px;

      &::before {
        margin-right: 13%;
      }
    }
    &__item dd {
      margin-left: 20%;
    }
  }
}
</style>
