<template>
  <Workshop />
</template>

<script>
import Workshop from "@/components/workshop/Workshop";

export default {
  name: "WorkshopPage",
  props: {},
  components: {
    Workshop,
  },
  data: () => ({}),
  computed: {},
  methods: {},
};
</script>
