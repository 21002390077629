<template>
  <div class="social">
    <a
      v-for="item in social"
      :key="item.provider_id"
      :href="makeSocialLink(item)"
      target="_blank"
      rel="noopener noreferrer"
    >
      <img
        :src="getSocialIcon(item)"
        :alt="`${item.provider} icon`"
        class="social-item"
      />
    </a>
  </div>
</template>

<script>
export default {
  name: "SocialMedia",
  props: ["social"],
  computed: {
    makeSocialLink() {
      return (social) => {
        let link = "";
        if (social.provider) {
          if (social.provider.includes("linkedin")) {
            if (social.provider === "linkedin_company") {
              link = "https://linkedin.com/company/" + social.provider_id;
            }
            if (social.provider === "linkedin") {
              link = "https://linkedin.com/in/" + social.provider_id;
            }
          } else if (social.provider === "reddit") {
            link = "https://reddit.com/r/" + social.provider_id;
          } else if (social.provider === "spotify") {
            link = "https://open.spotify.com/" + social.provider_id;
          } else if (
            social.provider.includes("www") ||
            social.provider.includes("web")
          ) {
            link = social.provider_id;
          } else {
            link = "https://" + social.provider + ".com/" + social.provider_id;
          }
        } else {
          link = social.provider_id;
        }
        if (social.provider_id.includes("https://")) link = social.provider_id;

        return link;
      };
    },
  },
  methods: {
    getSocialIcon(soc) {
      if (soc.provider_id.includes("https://"))
        return require(`../../assets/img/icons/social/web.svg`);
      if (soc.provider === "linkedin_company")
        return require(`../../assets/img/icons/social/linkedin.svg`);
      try {
        return require(`../../assets/img/icons/social/${soc.provider}.svg`);
      } catch (error) {
        return require("../../assets/img/icons/social/web.svg");
      }
    },
  },
};
</script>

<style scoped lang="scss">
.social {
  &-item {
    width: 30px;
    height: 30px;
    margin-right: 24px;

    &:hover {
      filter: contrast(9);
    }
  }
}
</style>
